import {IOrderUser} from "./ShopPageCheckoutForm";
import {validate} from "../../../services/utils";
import shopApi from "../../../api/shop";
import {makeSale} from "../Web3Modal/componentsWeb3/makeSale";
import {IFormObjStructure} from "../ShopPageCheckout";

export const allowedCarrierList = ["usps", "mpups"];


interface IDataProps {
    address1?: string
    address2?: string
    city?: string
    company_name?: string
    country?: string
    email?: string
    first_name?: string
    last_name?: string
    phone?: string
    postcode?: string
    save_as_address?: boolean
    state?: string
    use_for_shipping?: boolean
}

export const billingShippingCounter = (
    sameAddressOrNot: boolean,
    newAddress: boolean,
    save: boolean,
    shippingData: IDataProps,
    billingData: IDataProps,
    prevOrder: IOrderUser[],
    additionalValue: string,
    email: string,
    defaultID: any,
    chosenAddress?: IOrderUser
) => {
    // console.log(shippingData, "shippingData!!!!!")
    // console.log(billingData, "billingData!!!!!")
    // console.log(email, "email!!!!!")

    let errorObject: any
    let shipping, shipData: any
    let billing, billData: any
    const currentUser = prevOrder?.find((user: IOrderUser) => user.id === defaultID)
    const current = {
        ...currentUser,
        address1: [currentUser?.address1[0]],
        address2: [currentUser?.address1[1]],
        email: newAddress ? shippingData.email : email
    }
    // console.log(current, "current!!!")
    if (!!currentUser) { //true
        // console.log("1111")
        if (sameAddressOrNot) {
            // console.log("2222")
            if (newAddress) {
                // console.log("3333")
                billData = {...shippingData, address1: [shippingData.address1], address2: [shippingData.address2]}
                shipData = {...shippingData, address1: [shippingData.address1], address2: [shippingData.address2]}
                errorObject = {billErrors: {...validate(billData)}, shipErrors: {}}
            } else {
                // console.log("4444")
                billData = current
                shipData = current
                errorObject = {billErrors: {}, shipErrors: {}}
            }
        }
        if (!sameAddressOrNot) {
            // console.log("5555")
            if (newAddress) {
                // console.log("6666")
                billData = {...shippingData, address1: [shippingData.address1], address2: [shippingData.address2]}
                shipData = {...billingData, address1: [billingData.address1], address2: [billingData.address2]}
                errorObject = {billErrors: {...validate(billData)}, shipErrors: {...validate(shipData)}}
            } else {
                // console.log("7777", email, "____")
                billData = current
                shipData = {...billingData, address1: [billingData.address1], address2: [billingData.address2]}
                errorObject = {billErrors: {}, shipErrors: {...validate(shipData)}}
            }
        }
    }
    if (!currentUser) { //false
        // console.log("8888")
        billData = {...shippingData, address1: [shippingData.address1], address2: [shippingData.address2]}
        if (sameAddressOrNot) {
            // console.log("9999")
            shipData = {...shippingData, address1: [shippingData.address1], address2: [shippingData.address2]}
            errorObject = {billErrors: {...validate(billData)}, shipErrors: {...validate(shipData)}}
            // console.log(shipData, "__________---------++++++++++++++++")
        } else {
            // console.log("10000000")
            shipData = {...billingData, address1: [billingData.address1], address2: [billingData.address2]}
            errorObject = {billErrors: {...validate(billData)}, shipErrors: {...validate(shipData)}}
        }
    }
    billing = {
        use_for_shipping: sameAddressOrNot,
        save_as_address: save,
        address1: billData!.address1,
        address2: billData!.address2,
        email: shippingData.email || email,
        first_name: billData!.first_name,
        last_name: billData!.last_name,
        city: billData!.city,
        country: billData!.country,
        state: billData!.state,
        postcode: billData!.postcode,
        phone: billData!.phone,
        company_name: '',
        additional: additionalValue,
    }
    shipping = {
        use_for_shipping: sameAddressOrNot,
        save_as_address: save,
        address1: shipData!.address1,
        address2: shipData!.address2,
        email: shippingData.email || email,
        first_name: shipData!.first_name,
        last_name: shipData!.last_name,
        city: shipData!.city,
        country: shipData!.country,
        state: shipData!.state,
        postcode: shipData!.postcode,
        phone: shipData!.phone,
        company_name: '',
        additional: additionalValue,
    }
    // console.log(billing, "billing __ OUT")
    // console.log(shipping, "shipping __ OUT")
    return {
        shipping,
        billing,
        errorObject,
    }
}

interface IRedirect {
    redirectUrl: string
    customer: {
        token: string
    }
    router: any
    paymentName: string
    callPayWithStripe: any
    saveOrderResponse: any;
    stripeElement: string
}

export const checkoutSubmitFunction = async (
    paymentName: string,
    setErrorsCrypto: (value: string) => void,
    addressed: any,
    maxApprove: any,
    currentBusd: any,
    busdBalance: any,
    billing: IFormObjStructure,
    shipping: IFormObjStructure,
    token: any,
    customer: any,
    shipMethodR: string,
    providerState: any,
    shopId: any,
    sellerId: any,
    setLoadingPay: (value: boolean) => void,
    router: any,
    callPayWithStripe: (value: boolean) => void,
    stripeElement: any
) => {
    try {
        setErrorsCrypto('');

        if (paymentName === 'cryptopay') {
            if (!addressed.addressAccount) {
                setErrorsCrypto('Please connect wallet and approve your balances before clicking Pay');
                return;
            }

            if (maxApprove <= 0) {
                setErrorsCrypto('Please approve your balances before clicking Pay');
                return;
            }

            if (currentBusd > busdBalance) {
                setErrorsCrypto('Fail with error “insufficient funds');
                return;
            }
        }

        /*********************** Address saving request ************************/
        const saveAddressResponse = await shopApi.setSaveAddress(billing, shipping, token.cartToken, customer.token, true);

        if (!saveAddressResponse.ok) {
            setLoadingPay(false);
            return;
        }

        /*********************** Shipping saving request ************************/
        const saveShippingResponse = await shopApi.setSaveShipping(shipMethodR, token.cartToken, customer.token);

        if (!saveShippingResponse.ok) {
            setLoadingPay(false);
            return;
        }

        /*********************** Payment saving request ************************/
        const savePaymentResponse = await shopApi.setSavePayment(paymentName, token.cartToken, customer.token);

        if (!savePaymentResponse.ok) {
            setLoadingPay(false);
            return;
        }

        /*********************** Order saving request ************************/
        const saveOrderResponse = await shopApi.setSaveOrder(token.cartToken, customer.token);

        if (saveOrderResponse.success) {
            if (saveOrderResponse.order_details) {
                const status = await makeSale(providerState, shopId, saveOrderResponse.order_details, sellerId);

                if (status.status === 'success') {
                    setLoadingPay(false);
                    const url = `/thanks?orderID=${saveOrderResponse.order_details.id}`;
                    router.push(url, url);
                } else {
                    setLoadingPay(false);
                    setErrorsCrypto('Something went wrong. Contact the shop administrator');
                }
            } else {
                redirectToPaymentPage({
                    redirectUrl: saveOrderResponse.redirect_url,
                    customer,
                    router,
                    paymentName,
                    callPayWithStripe,
                    // saveShippingResponse
                    saveOrderResponse,
                    stripeElement
                });
            }
        } else {
            setLoadingPay(false);
        }
    } catch (error) {
        console.log(error, 'error');
        setLoadingPay(false);
    }
}

function redirectToPaymentPage(
    {
        redirectUrl,
        customer,
        router,
        paymentName,
        callPayWithStripe,
        // saveShippingResponse
        saveOrderResponse,
        stripeElement
    }: IRedirect) {
    if (redirectUrl && redirectUrl !== 'stripe') {
        if (paymentName === 'paypal_standard') {
            redirectUrl = `${redirectUrl}?token=${customer.token}`;
        }
        router.push(redirectUrl, redirectUrl);
    } else if (redirectUrl === 'stripe') {
        if (stripeElement === "error") {
            callPayWithStripe((prev: boolean | number) => typeof prev === "boolean" ? 1 : prev + 1)
        } else {
            callPayWithStripe(true);
        }
    } else {
        // const url = saveShippingResponse?.order?.id ? `/thanks?orderID=${saveShippingResponse.order.id}` : `/thanks?orderID=${saveShippingResponse.id}`;
        const url = saveOrderResponse?.order?.id ? `/thanks?orderID=${saveOrderResponse.order.id}` : `/thanks?orderID=${saveOrderResponse.id}`;
        router.push(url, url);
    }
}

export const checkNumberOrText = (value: any) => {
    if (isNaN(value)) {
        return value
    }
    if (!value) {
        value = "2-5"
    }
    return `${value} business day(s)`
}

export const carrierChecker = (carrier: string) => {
    return allowedCarrierList.includes(carrier);
}

export const truncateZeroUspsUps = (arr: any) => {
    return arr.filter((rate: any, index: number) => {
        if(rate.carrier === "mpups" && rate.price === 0 || rate.carrier === "usps" && rate.price === 0) {
            console.log(rate, "_______________------------------_____________________-")
            return arr.splice(index, 1)
        }
        return arr
    })
}
