import {
    UPDATE_CART_DATA,
    SET_PROMOTIONS,
    SET_PROMOTIONS_FREE_DESCRIPTION,
    SET_DISCOUNT_PRICE,
    SET_SHIPPING_TAX,
} from "./cartActionTypes";
import shopApi from "../../api/shop";
import {setPopup, setUpCrossProd} from "../general";
import {setShipMethodRate, setShippingZero} from "../checkout";
import {ICartItemType} from "../../types/state";
import {Dispatch} from "react";

const InitialState = {
    grand_total: "",
    sub_total: "",
    items: []
}

/********************** Helpers Part **********************/
const dispatchValueActionHandle = (fetchedCartData: { grand_total: string; items: ICartItemType, sub_total: string }) => {
    const {grand_total, items, sub_total} = fetchedCartData || InitialState;
    return {
        type: UPDATE_CART_DATA,
        payload: {
            grand_total,
            sub_total,
            items,
        },
    }
}

const isShippingZero = (promotions: { free_shipping: number; description: string }[], dispatch: Dispatch<{type: string; payload: any}>) => {
    if (promotions?.length > 0) {
        return promotions.some((promotion) => {
            const { free_shipping, description } = promotion
            if (free_shipping === 1) {
                dispatch(setPromotionsFreeDescription(description))
                return true
            }
            return false
        })
    }
}

const handleTypes = (type: string, payload: any) => { return { type, payload } }

/********************** PROMOTIONS Part **********************/
export const setPromotions = (payload: any[]) => handleTypes(SET_PROMOTIONS, payload);
export const setPromotionsFreeDescription = (payload: string) => handleTypes(SET_PROMOTIONS_FREE_DESCRIPTION, payload);
export const setDiscountPrice = (payload: string) => handleTypes(SET_DISCOUNT_PRICE, payload)
export const setCartShipTaxes = (payload: {
    coupon_code?: string | null;
    coupon_discount?: string;
    subtotal?: string;
    tax: string;
    total?: string
}) => handleTypes(SET_SHIPPING_TAX, payload)

/********************** Cart Actions Part **********************/

export function cartAddItem(
    { id }: { id: number },
    quantity: number,
    { cartToken }: { cartToken: string },
    { token }: { token: string },
    locale: string,
    optionsObject?: { customizable_options: Record<string, string | number> } | null
) {
    return async (dispatch: Dispatch<{ type: string; payload: { grand_total: string; items: ICartItemType; sub_total: string }}>) => {
        try {
            const customerToken = token ? { token } : {}
            const body = {
                api_token: cartToken,
                product_id: id,
                quantity: quantity,
                qty: quantity,
                ...customerToken,
                customizable_options: optionsObject?.customizable_options,
                locale
            };
            const { data } = await shopApi.cartAddItem({productId: id, body});
            dispatch(dispatchValueActionHandle(data))
        } catch (error) {
            console.log(error);
        }
    }
}

export function cartRemoveItem(itemId: number, { cartToken }: { cartToken: string }, customer: { token: string }) {
    return async (dispatch: Dispatch<{ type: string; grand_total?: string; items?: ICartItemType }>) => {
        try {
            const handleQueryParam = () => {
                let loggedIn = ""
                if (customer?.token) {
                    loggedIn = `&token=${customer.token}`
                }
                return `?api_token=${cartToken}${loggedIn}`
            }
            const { data } = await shopApi.cartRemoveItem({ productId: itemId, queryParam: handleQueryParam()})
            const { promotions, discount } = data || {};
            dispatch(setShippingZero(isShippingZero(promotions, dispatch)))
            dispatch(setPromotions(promotions))
            dispatch(setDiscountPrice(discount))
            dispatch(dispatchValueActionHandle(data))
        } catch (error) {
            console.log(error)
        }
    }
}

export function cartUpdateQuantities(
    quantities: {cartItem: number; itemId: number; value: number}[],
    cartItems: Record<string, { id: number }>,
    customerToken: { token: string },
    { cartToken }: { cartToken: string }
) {
    const qty = quantities.reduce((
        acc: Record<string, number>,
        upItem: {cartItem: number; itemId: number; value: number},
        index: number
    ) => {
        acc[cartItems[index]?.id] = upItem.value;
        return acc;
    }, {});

    return async (dispatch: Dispatch<{type: string; payload: { grand_total: string; items: ICartItemType; sub_total: string}}>) => {
        try {
            const customerTokenSetter = customerToken?.token ? {token: customerToken.token} : {}
            const body = {
                ...customerTokenSetter,
                api_token: cartToken,
                qty: qty,
            };
            const { data } = await shopApi.cartQuantityUpdate({body})
            dispatch(dispatchValueActionHandle(data))
        } catch (error) {
            console.log(error)
        }
    }
}
export function getCartDataMount(cartToken: string, customer: { token: string }, locale: string) {
    return async (dispatch: Dispatch<{ type: string; grand_total?: string; items?: ICartItemType }>) => {
        try {
            const customerToken = customer && customer?.token ? `&token=${customer.token}` : ""
            const { data } = await shopApi.getCartData({ cartToken, customerToken, locale })
            const { selected_shipping_rate } = data || {}
            const { price } = selected_shipping_rate || {};
            dispatch(setShipMethodRate(price))
            dispatch(dispatchValueActionHandle(data))
        } catch (error) {
            console.log(error)
        }
    }
}

export const getUpOrCrossProd = (prodID: number, type: string, locale: string, currency: string, rate = 1) => {
    return (dispatch: Dispatch<{ type: string; grand_total?: string; items?: ICartItemType }>) => {
        try {
            shopApi.getUpOrCrossSellProducts(prodID, locale, currency, rate, type)
                .then(res => {
                    dispatch(setUpCrossProd(res))
                    dispatch(setPopup(true))
                })
        } catch (error) {
            console.log(error)
        }
    }
}