import {megaUrl, url} from "../helper";


/******************* controllers import *************************/

const PageControllers = require("../controllers/PageControllers");
const ProductController = require("../controllers/ProductController");
const CategoryControllers = require("../controllers/CategoryController.js");
const FilterControllers = require("../controllers/FilterControllers");
const BuilderController = require("../controllers/BuilderController");
const SettingsController = require("../controllers/SettingsController.js");
const BlogControllers = require("../controllers/BlogControllers");
const FieldsController = require("../controllers/FieldsController");


// @ts-ignore
const shopApi = {

    customerResetPassword: async (object: any) => {
        const response = await fetch(`${megaUrl}/api/customer/reset-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(object),
        });
        return await response.json();
    },

    getBrands: async ({
                          options = {
                              locale: ""
                          }
                      }: { options?: { locale: string } }) => {
        const response = await fetch(`${url}/db/attributes?code=brand&locale=${options.locale}`);
        return await response.json();
    },

    /**
     * Returns product.
     *
     * @param {string} slug
     *
     * @param options
     * @return {Promise<object>}
     */
    getProductBySlug: async ({
                                 slug, options = {
            lang: '',
            token: false,
            selectedRate: undefined
        }
                             }: {
        slug: string,
        options?: { selectedRate: undefined; lang: string; token: boolean }
    }): Promise<object> => {
        let token = "";
        let lang: string = "en";
        if (options.lang) {
            lang = options.lang;
        }
        if (options.token) {
            token = `?token=${options.token}`;
        }

        const res = await fetch(`${url}/db/product/${slug}${token}?locale=${lang}&rate=${options.selectedRate}`);
        return await res.json();
    },

    /**
     * Returns an array with up-sell product
     *
     * @param productId
     * @param lang
     * @param currency
     * @param rate
     * @param type
     */
    getUpOrCrossSellProducts: async (productId: number, lang = "en", currency = "USD", rate = 1, type: string) => {
        const queryParams = new URLSearchParams({
            limit: "8",
            product_id: productId.toString(),
            locale: lang,
            currency,
            rate: rate.toString(),
        });
        const url = `${megaUrl}/db/${type}-products?${queryParams.toString()}`;
        try {
            const response = await fetch(url);
            return await response.json();
        } catch (err) {
            return console.log(err, "-----#############------error-----#############------ in getUpOrCrossSellProducts");
        }
    },


    /************** Cart Data Requests **********************/

    cartAddItem: async (attr: { productId: number, body: any }) => {
        try {
            const {productId, body} = attr
            const response = await fetch(`/api/checkout/cart/add/${productId}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            })
            return await response.json()
        } catch (err) {
            console.log(err)
        }
    },

    cartRemoveItem: async (attr: { productId: number, queryParam: string }) => {
        try {
            const {productId, queryParam} = attr
            const response = await fetch(`/api/checkout/cart/remove-item/${productId}${queryParam}`)
            return await response.json()
        } catch (err) {
            console.log(err)
        }
    },

    cartQuantityUpdate: async (attr: { body: any }) => {
        try {
            const { body } = attr;
            const response = await fetch("/api/checkout/cart/update", {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            })
            return await response.json();
        } catch (err) {
            console.log(err)
        }
    },

    getCartData: async (attr: { cartToken: string, customerToken: string, locale: string }) => {
        try {
            const {cartToken, customerToken, locale} = attr
            return fetch(`${megaUrl}/api/checkout/cart?api_token=${cartToken}${customerToken}&locale=${locale}`)
                .then(res => res.json())
        } catch (err) {
            console.log(err)
        }
    },

    /**
     * Returns an array with cross-sell products
     *
     * @param productId
     * @param lang
     * @param currency
     * @param rate
     */
    getCrossSellProducts: async (productId: number, lang = "en", currency = "USD", rate = 1) => {
        try {
            const response = await fetch(`${megaUrl}/db/cross-sell-products?limit=8&product_id=${productId}&locale=${lang}&currency=${currency}&rate=${rate}`);
            return await response.json();
        } catch (err) {
            return console.log(err);
        }
    },

    /**
     * Return products list.
     *
     * @param {object?} options
     * @param {number?} options.page
     * @param {number?} options.limit
     * @param {string?} options.sort
     * @param {Object.<string, string>?} filters
     *
     * @return {Promise<object>}
     */

    getSeachProducts: ({
                           query, options = {
            lang: "",
            currency: ""
        }
                       }: { query: any, options?: { lang: any, currency?: any } }): Promise<object> => {
        let locale = "en";
        let currency = "AMD";

        if (options.lang) {
            locale = options.lang;
        }

        if (options.currency) {
            currency = options.currency;
        }
        return fetch(`/db/products?search=${query}&locale=${locale}&currency=${currency}&isSearch=true`)
            .then((response) => {
                return response.json()
            });
    },

    /************************* Requests with Controllers ***********************************/

    /**** returns SEO for home page ****/
    /** FIXME IMPORTANT!!! Get_Channels doesn't have locale key **/

    async getHomeMetaAsync(domain: string, locale: any, models: any) {
        return PageControllers
            .Get_Channels(locale, models)
            .then((meta: any) => {
                if (meta && meta.length > 0) {
                    let share_pic = meta[0].share_pic;
                    let translated_meta = meta[0].translations?.find((translation: any) => translation.locale === locale)
                    if (translated_meta !== undefined) {
                        return {...JSON.parse(translated_meta.home_seo), share_pic}
                    }
                    return {}
                }
                return {}
            });

    },


    /**** returns home page products ****/
    async getHomeProductsAsync(options: { locale: any; limit: number; }, domain: any, models: any) {
        return ProductController
            .Get_New_And_Featured_Products(options, models)
            .then((products: { featured: any; new: any; }) => {
                return {
                    featuredProducts: products.featured,
                    newProduct: products.new,
                };
            });
    },

    async getBuilderDataAsync(pageId: number, domain: any, locale: string, models: any) {
        return BuilderController
            .Get_Data(pageId, locale, models)
            .then((response: any) => {
                return response;
            });
    },

    async sliderData(domain: any, selectedLocale: any, models: any) {
        return SettingsController
            .Get_SliderData(selectedLocale, models)
            .then((response: any) => {
                return response;
            });
    },
    /**** returns categories ****/
    async getCategoriesAsync(locale: any, models: any) {
        return CategoryControllers
            .Get_Categories(locale, models)
            .then((response: any) => {
                return response;
            });
    },

    /**** returns categories page [filters] ****/
    async getFiltersAsync(options: any, domain: any, models: any) {
        return FilterControllers
            .Get_Filters(options, models)
            .then((response: any) => {
                return response;
            });
    },

    /**** returns all [filters] ****/
    async getAllFiltersAsync(options: any, domain: any, models: any) {
        return FilterControllers
            .Get_All_Filters(options, models)
            .then((response: any) => {
                return response;
            });
    },

    async getFields(locale: string, models: any) {
        return FieldsController
            .Get_Fields(locale, models)
            .then((response: any) => {
                return response;
            });
    },

    /**** returns categories page [products] ****/

    async Get_Category_Products(options: any, models: any, sortBy?: boolean, isInfitieScroll?: boolean) {
        return ProductController
            .Get_Category_Products(options.slug, options.locale, options.limit, models, options.page, sortBy, isInfitieScroll)
            .then((response: any) => {
                return response
            })
    },


    /**** returns all products ****/

    async Get_All_Products(options: any, models: any) {
        return ProductController
            .Get_All_Products(options.locale, models)
            .then((response: any) => {
                return response
            })
    },


    /****************** return Blogs  ***************/
    getAsyncBlogs: async function (options: {
        locale: string | undefined,
        page: string | number | string[],
        limit: number,
        dbName: string
    }, models: any) {
        return BlogControllers
            .Get_Blogs(options, models)
            .then((response: any) => {
                return response;
            });
    },
    async getAsyncPagesBySlug(options: { dbName: string; }, models: any) {
        return PageControllers
            .Get_Page_By_Slug(options, models)
            .then((response: any) => {
                return response
            });
    },
    async getAsyncBlogsBySlug(options: { locale: string, url_key: string, dbName: string }, models: any) {
        return BlogControllers
            .Get_Blog_By_Slug(options, models)
            .then((response: any) => {
                return response
            });
    },

    /*************************** PRODUCT INNER PAGE ASYNC REQUESTS ************************************/

    getProductBySlugAsync(slug: string, options: { locale: any }, models: any) {
        return ProductController
            .Get_Product_For_Product_Inner_Page(slug, options, models)
            // @ts-ignore
            .then(response => {
                let res = response
                if (!res.notFound) {
                    let exceptArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 29]
                    //     @ts-ignore
                    res[0].details = response[0].details.filter(el => {
                        return exceptArray.every(elem => el.attribute_id !== elem)
                        //     @ts-ignore
                    }).map(el => {
                        //     @ts-ignore
                        el.name = el.attribute[0]?.translations?.find((el) => el.locale === options.locale)?.name
                        if (el.name === undefined) el.name = null
                        return el
                        //     @ts-ignore
                    }).map(el => {
                        //     @ts-ignore
                        el.attribute_options = el.attribute_options.filter(elem => elem.attribute_id === el.attribute_id)
                        return el
                        //     @ts-ignore
                    }).filter(el => {
                        return el.integer_value ||
                            el.text_value ||
                            el.datetime_value ||
                            el.float_value ||
                            el.date_value
                    })
                }
                return res
            })
    },

    getRelatedProductsAsync(options: any, models: any) {
        return ProductController
            .Get_Related_Products(options, models)
    },

    getBundleProductAsync: (id: any, models: any) => {
        return ProductController
            .Get_Bundle_Prods(id, models)
    },

    async getShopId(neededIds: any, models: any) {
        return SettingsController
            .Get_ShopId(neededIds, models)
            .then((res: { value: any; }) => {
                if (res?.value) {
                    return res.value;
                }
                return res
            })

    },


// get usd value

    getUsdCurrentValue: () => {
        return fetch(`https://api.coingecko.com/api/v3/simple/price?ids=binance-usd&vs_currencies=usd`)
            .then(res => res.json())
    },

    /********************************** Checkout Page queries ***************************************************/
    getPayments: (locale = false) => {
        let hasLocale = locale ? `?locale=${locale}` : ""
        return fetch(`${megaUrl}/api/payments${hasLocale}`)
            .then(res => res.json())
    },


    checkOrder: (orderId: any, transactionID: any) => {
        let hasOrderId = orderId && transactionID ? `order_id=${orderId}&trans_id=${transactionID}` : ""
        return fetch(`${megaUrl}/api/crypto-check?${hasOrderId}`)
            .then(res => res.json())
    },

    getStripe: () => {
        return fetch(`${megaUrl}/api/checkout/getpk`)
            .then(res => res.json())
    },

    getCountryState: () => {
        return fetch(`${megaUrl}/api/country-states?pagination=0`)
            .then(res => res.json())
    },

    getCountries: () => {
        return fetch(`${megaUrl}/api/countries?pagination=0`)
            .then(res => res.json())
    },

    getCustomerToken: async (token: any, single: any) => {
        // @ts-ignore
        const res = await fetch(`${megaUrl}/api/customer/get?token=${token}`, {single});
        return await res.json();
    },

    getAddresses: async (token: any, single: AbortController) => {

        // @ts-ignore
        return fetch(`${megaUrl}/api/addresses?pagination=0&token=${token}`, {single})
            .then(res => res.json())
    },

    // getCartToken: (token: any) => {
    //     const headers = {
    //         Accept: 'application/json',
    //         'Content-Type': 'application/json',
    //     }
    //     return fetch(`${megaUrl}/api/checkout/cart?token=${token}`, {
    //         method: 'GET',
    //         headers: headers,
    //     })
    //         .then((res) => res.json())
    // },
    //
    // getCartCoupon: (methods: string, coupon: any, token: any) => {
    //     const requestOptions = {
    //         method: methods === "DELETE" ? "DELETE" : "POST",
    //         headers: {'Content-Type': 'application/json'},
    //         body: JSON.stringify({
    //             code: coupon,
    //             token: token,
    //         }),
    //     }
    //     return fetch(`${megaUrl}/api/checkout/cart/coupon`, requestOptions)
    //         .then((res) => res.json())
    // },

    setSaveAddress: (billing: any, shipping: any, cartToken: any, token: any, isFromPayButton: boolean = false) => {

        // const {general: {roomID}} = store.getState()
        const roomID = sessionStorage.getItem("roomID")

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }


        const mainBody = {
            billing,
            shipping,
            api_token: cartToken,
            token,
        }

        if (roomID) {
            Object.assign(mainBody, {room_id: roomID})
        }

        const body = !isFromPayButton ? {
            ...mainBody,
            is_order: 1
        } : {
            ...mainBody
        }

        if (!token) {
            delete body.token
        }


        const options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        }

        return fetch(`${megaUrl}/api/checkout/save-address`, options)
    },

    setSaveShipping: (shippingMethod: any, cartToken: any, token: string) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }

        const body = {
            shipping_method: shippingMethod === "" ? "free_free" : shippingMethod,
            api_token: cartToken,
            token: token,
        }
        ////FIXME FIX THIS PART
        // if (!token) {
        //     delete body.token
        // }

        const save_shipping = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        }

        let tokenURL = token ? "?token=" + token : "";
        return fetch(`${megaUrl}/api/checkout/save-shipping${tokenURL}`, save_shipping)
    },

    setSavePayment: (payment: any, cartToken: any, token: any) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        const body = {
            payment: {method: payment},
            api_token: cartToken,
            token: token,
        }

        if (!token) {
            delete body.token
        }

        const options_payment = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        }
        return fetch(`${megaUrl}/api/checkout/save-payment`, options_payment)
    },

    setSaveOrder: (cartToken: any, token: any) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }

        const body = {
            api_token: cartToken,
            token: token,
        }

        if (!token) {
            delete body.token
        }

        const options_order = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        }

        return fetch(`${megaUrl}/api/checkout/save-order`, options_order)
            .then(res => res.json())
    },


    /******************* shipping methods *********************/

    getShippingMethods: (locale: any, token: any) => {
        const realToken = token ? `&token=${token}` : ""
        return fetch(`${megaUrl}/api/shipping?locale=${locale}${realToken}`)
            .then(res => res.json())
    },

    /********************* cart requests ************************/

    getCartTranslation: (cartToken: { cartToken: any; }, customer: { token: any; }, locale: any) => {
        const realToken = customer && customer.token ? `&token=${customer.token}` : ""
        return fetch(`${megaUrl}/api/checkout/cart?api_token=${cartToken.cartToken}${realToken}&locale=${locale}`)
            .then(res => res.json())
    },


    /************************* Account component requests *****************************/

    getForgetPassword: (input: any) => {
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({email: input}),
        };
        return fetch(`${megaUrl}/api/customer/forget-password`, options)
            .then(res => res.json())
    },

    getCustomerLogoutToken: (customer: any) => {
        return fetch(`${megaUrl}/api/customer/logout?token=${customer}`)
    },

    getCheckoutCartToken: () => {
        return fetch(`${megaUrl}/api/checkout/cart/token`)
            .then(res => res.json())
    },

    getCustomerLoginToken: ({email, password}: { email: string, password: string }) => {
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        };

        return fetch(`${megaUrl}/api/customer/login?token=true`, options)
            .then(res => res.json())
    },

    // getCheckoutCartAdd: (productID: any, quantity: any, cartToken: any, token: any) => {
    //     const realToken = token ? `&token=${token}` : ""
    //     return fetch(`${megaUrl}/api/checkout/cart/add/${productID}?product_id=${productID
    //     }&quantity=${quantity}&api_token=${cartToken}${realToken}`, {method: "POST"})
    //         .then(res => res.json())
    //     // .then(data => console.log(data))
    // },

    getSmartSearchCats: (catID: any) => {
        return fetch(`${megaUrl}/db/smart-search/${catID}`)
            .then(res => res.json())
    },

    getProductsBySmartSearch: (options: any) => {
        return fetch(`${megaUrl}/db/smart-search/products/${options.slug}`)
            .then(res => res.json())
    },
    getSiteTitle: (locale: string, models: any) => {
        return SettingsController
            .Get_Translations({locale}, models)
            .then((res: any) => {
                if (locale) return res[locale]
            })
    },

    getCoreConf: (locale: string, models: any) => {
        return SettingsController
            .Get_Core_Config({locale}, models)
            .then((res: any) => res)
    },

    getLocale: (locale: string, models: any) => {
        return SettingsController
            .Get_Settings(locale, models)
            .then((res: any) => res.channel_info);
    },

    getMenus: async (locale: string, models: any) => {
        try {
            if (locale !== "catchAll") {
                return await CategoryControllers.Get_Menus(locale, models)
            }
        } catch (err) {
            throw err;
        }
    },

    getCustomFields: async (locale: string, models: any) => {
        try {
            return await SettingsController.Get_Custom_Fields(locale, models)
        } catch (err) {
            throw err;
        }
    }

    /************************* Smart search requests *****************************/
};


export default shopApi;
