import store from "../store";
import React from "react";
import {FormattedMessage} from "react-intl";

export const url = {
    home: () => "/",

    catalog: () => "/catalog",

    category: (category, localeQuery = "") => {
        let stringQuery = `${localeQuery}/catalog/${category.slug ? category.slug : category.department.slug}`
        if (category && category.query && category.query.page) {
            return `${stringQuery}?page=1`;
        }
        if (category && category.query && category.query.currencies) {
            return `${stringQuery}?currencies=${category.query}`;
        } else {
            return `${stringQuery}`;
        }
    },

    product: (product) => {
        if (product.product_flat && product?.product_flat.length > 0) {

            return `/${product.product_flat[0].url_key}`;
        } else {
            return `/${product.url_key}`;

        }


    },
}

export function convertStyles(settings) {
    let top = 0;
    let right = 0;
    let bottom = 0;
    let left = 0;
    let paddTop = 0;
    let paddRight = 0;
    let paddBottom = 0;
    let paddLeft = 0;
    let fontFamily = "";
    let image = [];
    let color = "";
    let width = "";
    let height = "initial";
    let align = "";
    let richEditorValue = "";
    let accordionTitle = "";
    let accordionDescription = "";
    let borderTopLeftRadius = "";
    let borderTopRightRadius = "";
    let borderBottomLeftRadius = "";
    let borderBottomRightRadius = "";
    let position = "";
    let zIndex = "";
    let positionLeft = "";
    let positionRight = "";
    let positionTop = "";
    let positionBottom = "";
    let backgroundColor = "";
    let animationAosInit = '';

    settings.map((item) => {
        Object.keys(item).forEach((key) => {
            if (key !== undefined) {
                switch (key) {
                    case 'data-aos':
                        return (animationAosInit = item['data-aos']);
                    case "marginTop":
                        return (top = item.marginTop);
                    case "marginRight":
                        return (right = item.marginRight);
                    case "marginBottom":
                        return (bottom = item.marginBottom);
                    case "marginLeft":
                        return (left = item.marginLeft);
                    case "paddingTop":
                        return (paddTop = item.paddingTop);
                    case "paddingRight":
                        return (paddRight = item.paddingRight);
                    case "paddingBottom":
                        return (paddBottom = item.paddingBottom);
                    case "paddingLeft":
                        return (paddLeft = item.paddingLeft);
                    case "font-family":
                        return (fontFamily = item["font-family"]);
                    case "color":
                        return (color = item.color);
                    case "width":
                        return (width = item.width);
                    case "height":
                        return (height = item.height);
                    case "text-align":
                        return (align = item["text-align"]);
                    case "border-top-left-radius":
                        return (borderTopLeftRadius = item["border-top-left-radius"]);
                    case "border-top-right-radius":
                        return (borderTopRightRadius = item["border-top-right-radius"]);
                    case "border-bottom-left-radius":
                        return (borderBottomLeftRadius = item["border-bottom-left-radius"]);
                    case "border-bottom-right-radius":
                        return (borderBottomRightRadius = item["border-bottom-right-radius"]);
                    case "position":
                        return (position = item.position);
                    case "z-index":
                        return (zIndex = item["z-index"]);
                    case "left":
                        return (positionLeft = item.left);
                    case "right":
                        return (positionRight = item.right);
                    case "top":
                        return (positionTop = item.top);
                    case "bottom":
                        return (positionBottom = item.bottom);
                    case "background-color":
                        return (backgroundColor = item["background-color"])
                    default:
                        return false;
                }
            }
        });
    });
    const parentStyles = {
        textAlign: `${align}`
    };

    let animationAos = {
        ['data-aos']: animationAosInit,
    }

    const styles = {
        margin: `${top}px ${right}px ${bottom}px ${left}px`,
        padding: `${paddTop}px ${paddRight}px ${paddBottom}px ${paddLeft}px`,
        fontFamily: `${fontFamily}`,
        color: color,
        backgroundColor: backgroundColor,
        width: `${width}px`,
        height: height ? `${height}px` : "unset",
        borderTopLeftRadius: `${borderTopLeftRadius}px`,
        borderTopRightRadius: `${borderTopRightRadius}px`,
        borderBottomLeftRadius: `${borderBottomLeftRadius}px`,
        borderBottomRightRadius: `${borderBottomRightRadius}px`,
        position: position,
        zIndex: `${zIndex}px`,
        left: `${positionLeft}px`,
        right: `${positionRight}px`,
        top: `${positionTop}px`,
        bottom: `${positionBottom}px`,
    };
    return {styles, parentStyles, animationAos}
}

export function runFbPixelEvent(eventData) {
    const {
        general: {fbPixel},
    } = store.getState();
    setTimeout(() => {
        if (fbPixel) {
            typeof window !== undefined ? fbq("track", eventData?.name) : null;
        }
    }, 500);
}


const replacer = (string, filterName) => {
    const index = string.indexOf(filterName);
    if (index > -1) {
        const endIndex = string.includes('&') ? string.indexOf('&', index) + 1 : string.length;
        const substringToRemove = string.substring(index, endIndex);
        const newString = string.replace(substringToRemove, '');
        return replacer(newString, filterName); // Recursive call
    }
    return string;
};

export const rightUrl = (path, router) => {
    if (router.asPath.includes("?")) {
        let x = `${path}${router.asPath.slice(router.asPath.indexOf("?"))}`
        let y = replacer(clearQueryPath(x), "page");
        return y.indexOf("?") === y.length - 1 ? y.slice(0, y.length - 1) : y
    }
    return path
}

const clearQueryPath = (string) => {
    let ab;
    if (string.includes("filter_")) {
        ab = replacer(string, "filter")
        if (ab.includes("filter_")) {
            return clearQueryPath(ab)
        }
        return ab
    }
    return string
}


export const getSeparateDomain = (dbName) => {
    let databaseName;
    if (dbName.includes(process.env.APP_SUFFIX)) {
        let dataName = dbName.split(process.env.APP_SUFFIX);
        databaseName = dataName[0];
        process.env.domainName = dbName;
        process.env.databaseName = databaseName;
    } else {
        process.env.domainName = dbName;
        databaseName = dbName.split(".")[0] === "www" ? dbName.split(".")[1] : dbName.split(".")[0];

        process.env.databaseName = databaseName;
    }
    return databaseName
}


export const replaceDescr = (str) => {
    if (str) {
        let obj = {}
        let newStr = str
        while (newStr.includes("<table")) {
            let start = newStr.lastIndexOf("<table")
            let end = newStr.lastIndexOf("</table>") + 8

            obj[newStr.slice(start, end)] = [start, end]

            newStr = newStr.replace(newStr.slice(start, end), '')
        }
        for (let key in obj) {
            let start = obj[key][0]
            let end = obj[key][1]
            let value = `<div className="table_wrapper" style="overflow: scroll">${key}</div>`
            str = str.replace(str.slice(start, end), value)
        }
        return str
    }
}

export const detectLocale = (arr, locale) => {
    let newArr;
    if (arr && arr.length > 0) {
        newArr = arr.find(el => el.locale_code === locale)
        if (newArr === undefined) {
            return {value: false}
        }
        return newArr
    }
    return {value: false}
}

export const genKey = (message) => {
    return message?.replaceAll(" ", "_").toLowerCase()
}

export const idGen = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}


export const createBuilderData = (array, arrayElement) => {
    let data = array?.map((el) => {
        if (Object.keys(el)[0] === arrayElement) {
            return Object.values(el)[0]
        }
    })
    data = data?.filter(element => element !== undefined);
    return data[0]
}

export const isDefaultImage = (url) => {
    return url.split('/').includes('_next')
}

export const checkDetail = (details) => {
    if (details && details.length > 0) {
        return details.some(el => +el.attribute?.[0]?.is_visible_on_front === 1)
    } else return false
}

export const setTranslatable = (key, value) => {
    return <FormattedMessage id={key} defaultMessage={value}/>
}

// export const validate = (currentForm, hasState) => {
export const validate = (currentForm) => {

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let errors = {}
    for (const [key, value] of Object.entries(currentForm)) {
        if (key !== "additional" && key !== "company_name" && key !== "address2" &&
            (
                (Array.isArray(value) && !Boolean(value[0])) ||
                (!Array.isArray(value) && typeof value !== "boolean" && !Boolean(value)) ||
                // (key === "state" && hasState && value === "no state")
                (key === "state" && value === "no state")
            )) {
            errors[`${key}_error`] = "this field is required"
        }

        if (key === "email" && !regex.test(value)) {
            errors[`${key}_error`] = "invalid email format"
        }
    }
    return errors
}

export const renderMapData = (initialLayout, forMap = "class") => {
    if (initialLayout && initialLayout.length) {
        return initialLayout?.map((item) => {
            return Object.keys(item).map((element) => {

                return item[Number(element)].map((child) => {
                    if (forMap === "backgroundColor") {
                        return child.backgroundColor
                    } else if (forMap === "alignItems") {
                        return child.alignItems
                    } else if (forMap === "fullScreen") {
                        return child.fullScreen
                    } else if (forMap === "backgroundImage") {
                        return child.backgroundImage
                    } else if (forMap === "bgImageHeight") {
                        return child.bgImageHeight
                    } else if (forMap === "alignItemsRow") {
                        return child.alignItemsRow
                    } else if (forMap === "column") {
                        return child.children
                    }
                    return child.children.map((children) => {
                        return children.class
                    })
                })
            })
        })
    }
}