import shopApi from 'api/shop';
import React from 'react'
import Eccomerce_DAOABI from "../utils/ABI/eccomerseDAO.json"
import {eccomerseDao} from "../utils/contractAddress"


export const makeSale = async (provider, shopId, order_details, sellerId) => {

    let accounts = await provider.providerState.eth.getAccounts()
    let contr = await new provider.providerState.eth.Contract(
        Eccomerce_DAOABI,
        eccomerseDao
    )
    let amount = provider.providerState.utils.toWei(String(order_details.sub_total_in_busd))

    let response = await contr.methods.makesale(shopId, order_details.id, amount, sellerId).send({
        from: accounts[0],
    });

    const order_detail = await contr.methods.getOrderDetail(order_details.id).call()
    const checked = await shopApi.checkOrder(order_details.id, response.transactionHash)

    return checked
}