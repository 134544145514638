// noinspection JSUnresolvedVariable
const settingConstants = require("./constants")

function Get_SliderData(locale, models) {
    return new Promise((resolve, reject) => {
        models
            .sliders
            .find()
            .then((res) => {
                const sliders = JSON.parse(JSON.stringify(res));
                const filteredSliders = sliders.filter((slider) => {
                    return slider.locale.includes(locale) &&
                        !slider.expired_at?.includes(new Date().toISOString().slice(0, 10));
                });
                const slidersSortBy = filteredSliders.sort((a, b) => a.sort_order - b.sort_order)
                resolve({sliders: slidersSortBy})
            })
            .catch((err) => reject(err));
    });
}

function Get_ChanelInfo(models) {
    return new Promise((resolve, reject) => {
        models
            .channel_info
            .find()
            .then((res) => resolve({channel_info: JSON.parse(JSON.stringify(res))}))
            .catch((err) => reject(err));
    });
}

function Get_Settings(locale, models) {
    const storeName = process.env.databaseName;
    const p1 = new Promise((resolve, reject) => {
        models
            .channel_info
            .find()
            .then((res) => resolve({channel_info: JSON.parse(JSON.stringify(res))}))
            .catch((err) => reject(err));
    });

    const p2 = new Promise((resolve, reject) => {
        models
            .social
            .find()
            .then((res) => {
                resolve({social: JSON.parse(JSON.stringify(res))})
            })
            .catch((err) => reject(err));
    });

    return new Promise((resolve, reject) => {
        Promise
            .all([p1, p2])
            .then(async (responseArray) => {
                let [customSettingsCollection] = await Promise.all([responseArray.reduce((acc, next) => {
                    const [key] = Object.keys(next);
                    return {
                        ...acc,
                        [key]: next[key],
                    };
                }, {})]);
                customSettingsCollection.folder_name = storeName;
                resolve(customSettingsCollection);
            })
            .catch((err) => reject(err));
    });
}

function Get_Core_Config(locale, models) {
    return new Promise((resolve, reject) => {
        models
            .core_config
            .aggregate([
                {
                    $match: {
                        code: {
                            $in: [
                                settingConstants.Phone,
                                settingConstants.Email,
                                settingConstants.Footer_Address,
                                settingConstants.Custom_Css,
                                settingConstants.Custom_Javascript,
                                settingConstants.Gdpr_Content,
                                settingConstants.Gdpr_Title,
                                settingConstants.Gdpr_Active,
                                settingConstants.Out_Of_Stock,
                                settingConstants.Guest_Checkout,
                                settingConstants.Backorders,
                                settingConstants.Blog_Active,
                                settingConstants.Contact_Us_Active,
                                settingConstants.Subscribe_Active,
                                settingConstants.Tracking_Active,
                                settingConstants.Linkedin_Active,
                                settingConstants.Facebook_Active,
                                settingConstants.Twitter_Active,
                                settingConstants.Github_Active,
                                settingConstants.Google_Active,
                                settingConstants.Cache_Version,
                                settingConstants.Footer_Toggle,
                                settingConstants.Footer_Content,
                                settingConstants.Recaptcha_active,
                                settingConstants.Recaptcha_key,
                            ],
                        },
                    }
                },
                {
                    $group: {
                        _id: "$code",
                        matchingDocuments: {
                            $push: {
                                id: "$id",
                                value: "$value",
                                channel_code: "$channel_code",
                                locale_code: "$locale_code",
                                created_at: "$created_at",
                                updated_at: "$updated_at"
                            }
                        }
                    }
                }
            ])
            .then((res) => {
                const core_conf = res
                    .map(el => {
                        let {_id, matchingDocuments} = el
                        let key = _id.replace(/\./g, "_");
                        let replaceSlash = key.replace(/\-/g, "_");
                        return {[replaceSlash]: matchingDocuments};
                    })
                    .map(el => {
                        for (let key in el) {
                            if (el[key][0].locale_code) {
                                return el
                            } else return {[key]: el[key][0]}
                        }
                    })
                    .reduce((acc, next) => {
                        return {
                            ...acc,
                            ...next,
                        };
                    }, {})
                resolve(core_conf);
            })
            .catch((err) => reject(err));
    });
}

function Get_ShopId(query, models) {
    return new Promise((resolve, reject) => {
        let newQuery;
        if (typeof query === "object") {
            newQuery = query.locale
        } else {
            newQuery = query
        }
        models
            .core_config
            .findOne({code: newQuery})
            .then((res) => {
                resolve(JSON.parse(JSON.stringify(res)))
            })
            .catch((err) => reject(err));
    })

}

function Get_Translations(query, models) {
    return new Promise((resolve, reject) => {
        let newQuery;

        if (typeof query === "object") {
            newQuery = query.locale
        } else {
            newQuery = query
        }
        models
            .translations
            .findOne({lang: newQuery})
            .then((res) => {
                resolve({[newQuery]: {translations: res?.data ? JSON.parse(JSON.stringify(res.data)) : {}}});
            })
            .catch((err) => reject(err));
    });
}

module.exports = {
    Get_Settings,
    Get_Core_Config,
    Get_Translations,
    Get_ShopId,
    Get_SliderData,
    Get_ChanelInfo,
};
