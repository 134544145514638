import {
    UPDATE_CART_DATA,
    SET_POPUP,
    SET_PROMOTIONS,
    SET_PROMOTIONS_FREE_DESCRIPTION,
    SET_DISCOUNT_PRICE, SET_SHIPPING_TAX
} from "./cartActionTypes";
import {ICartItemType} from "../../types/state";

const initialState = {
    grand_total: "",
    sub_total: "",
    items: []
};

type actionType = {
    type: string;
    payload: any | {
        grand_total: string;
        items: ICartItemType;
        sub_total: string
    };
}

export default function cartReducer(state = initialState, action: actionType) {
    switch (action.type) {
        case UPDATE_CART_DATA:
            return {
                ...state,
                ...action.payload
            };
        case SET_POPUP:
            return {
                ...state,
                popUpName: action.payload,
            };
        case SET_PROMOTIONS:
            return {
                ...state,
                promotions: action.payload
            }
        case SET_PROMOTIONS_FREE_DESCRIPTION:
            return {
                ...state,
                free_shipped_description: action.payload
            }
        case SET_DISCOUNT_PRICE:
            return {
                ...state,
                discount_price: action.payload
            }
        case SET_SHIPPING_TAX:
            const { total, coupon_code, coupon_discount, tax } = action.payload || {}
            const totalResult = total ? { grand_total: total } : {}
            return {
                ...state,
                ...totalResult,
                coupon_code: coupon_code,
                coupon_discount: coupon_discount,
                tax: tax,
            }
        default:
            return state;
    }
}