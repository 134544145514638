import {
    RESET_CHECKOUT_COUNTRIES,
    SET_ALL_SHIPPING_RATES,
    SET_BIL_COUNTRY_HAS_STATES,
    SET_BILLING_COUNTRY,
    SET_BILLING_STATE,
    SET_SHIP_COUNTRY_HAS_STATES,
    SET_SHIPPING_COUNTRY,
    SET_SHIPPING_METHOD,
    SET_SHIPPING_RATE,
    SET_SHIPPING_STATE,
    SET_SHIPPING_ZERO,
    SET_SHOW_SHIPPING_ADDRESS,
    SET_SIGNED_USER_ADDRESS_ID, SET_STRIPE_ELEMENT,
} from "./checkoutActionTypes"
import {IFormObjStructure} from "../../components/shop/ShopPageCheckout";
import shopApi from "../../api/shop";
import {setCartShipTaxes, getCartDataMount, setDiscountPrice, setPromotions, setPromotionsFreeDescription} from "../cart";
import {carrierChecker, truncateZeroUspsUps} from "../../components/shop/ShopCheckoutChild/helper";

export const setBillingState = (payload: any) => {
    return {
        type: SET_BILLING_STATE,
        payload,
    };
};

export const setShippingState = (payload: any) => {
    return {
        type: SET_SHIPPING_STATE,
        payload,
    };
};

export const setShippingCountry = (payload: any) => {
    return {
        type: SET_SHIPPING_COUNTRY,
        payload,
    };
};

export const setBillingCountry = (payload: any) => {
    return {
        type: SET_BILLING_COUNTRY,
        payload,
    };
};
export const setShipCountryHasStates = (payload: any) => {
    return {
        type: SET_SHIP_COUNTRY_HAS_STATES,
        payload,
    };
};
export const setBilCountryHasStates = (payload: any) => {
    return {
        type: SET_BIL_COUNTRY_HAS_STATES,
        payload,
    };
};
export const setShippingZero = (payload: any) => {
    return {
        type: SET_SHIPPING_ZERO,
        payload,
    };
};

export const resetCheckoutCountries = (payload: any) => {
    return {type: RESET_CHECKOUT_COUNTRIES}
};

export const setBillingOrShippingCountry = (compName: string, country: string) => (dispatch: any) => {
    if (compName === "shipping") {
        dispatch(setShippingCountry(country))
    } else {
        dispatch(setBillingCountry(country))
    }
}

export const setBillingOrShippingState = (compName: string, state: string) => (dispatch: any) => {
    if (compName === "shipping") {
        dispatch(setShippingState(state))
    } else {
        dispatch(setBillingState(state))
    }
}

export const setBillingOrShippingHasState = (compName: string, hasState: boolean) => (dispatch: any) => {
    if (compName === "shipping") {
        dispatch(setShipCountryHasStates(hasState))
    } else {
        dispatch(setBilCountryHasStates(hasState))
    }
}

export const setShippingRates = (payload: string | any) => {
    return {
        type: SET_ALL_SHIPPING_RATES,
        payload,
    };
}

export const setSignedUserAddressI = (payload: number) => {
    return {
        type: SET_SIGNED_USER_ADDRESS_ID,
        payload,
    };
}

export const setShowShippingAddress = (payload: boolean) => {
    return {
        type: SET_SHOW_SHIPPING_ADDRESS,
        payload,
    };
}

export const setShipMethodRate = (payload: string | any) => {
    return {
        type: SET_SHIPPING_RATE,
        payload,
    };
}

export const setShipMethod = (payload: string) => {
    return {
        type: SET_SHIPPING_METHOD,
        payload,
    };
}

export const setStripeElement = (payload: any) => {
    return {
        type: SET_STRIPE_ELEMENT,
        payload,
    };
}

export const setAddressShipping = (billing: IFormObjStructure, shipping: IFormObjStructure, token: any, customer: any, locale: string) => async (dispatch: any) => {
    try {
        dispatch(setShippingRates([]));
        const addressResponse = await shopApi.setSaveAddress(billing, shipping, token.cartToken, customer.token);
        const responseData = await addressResponse.json();

        const discount = responseData?.data?.cart?.discount;
        dispatch(setDiscountPrice(discount))
        const rates = responseData?.data?.rates?.flatMap((el: any) => el.rates) || [];
        const filteredArray = truncateZeroUspsUps(rates).sort((a: any, b: any) => parseFloat(a.price) - parseFloat(b.price))
        if (filteredArray.length > 0) {
            dispatch(setShippingRates(filteredArray));
            dispatch(setShowShippingAddress(true));
        }
        const shippingMethod = filteredArray[0]?.method;

        const shippingResponse = await shopApi.setSaveShipping(shippingMethod, token.cartToken, customer.token);
        const shippingData = await shippingResponse.json();

        if (shippingData) {
            const promotions = shippingData?.data?.cart?.promotions;
            const isShippingZero = promotions?.some((promotion: any) => promotion.free_shipping === 1);

            if (isShippingZero) {
                dispatch(setPromotionsFreeDescription(promotions[0].description));
            }
            /*set the first element in array*/
            dispatch(setShipMethod(filteredArray[0]?.method));
            dispatch(setShipMethodRate(filteredArray[0]?.method.price?.toString()));

            dispatch(setShippingZero(isShippingZero));
            dispatch(setPromotions(promotions));

            dispatch(getCartDataMount(token.cartToken, customer, locale));
            dispatch(setCartShipTaxes({tax: shippingData?.data?.cart?.tax_total}));

            // if (shippingData.data.cart.selected_shipping_rate?.price.toString() === rates[rates.length - 1]?.price.toString()) {
            //     dispatch(setShipMethod(shippingData.data.cart.selected_shipping_rate.method));
            //     dispatch(setShipMethodRate(shippingData.data.cart.selected_shipping_rate.price.toString()));
            // }
            // if (shippingData.data.cart.selected_shipping_rate?.price.toString() === rates[0]?.price.toString()) {
            // if (shippingData.data.cart.selected_shipping_rate?.price.toString() === filteredArray[0]?.price.toString()) {
            //     dispatch(setShipMethod(shippingData.data.cart.selected_shipping_rate.method));
            //     dispatch(setShipMethodRate(shippingData.data.cart.selected_shipping_rate.price.toString()));
            // }
        }
    } catch (err) {
        console.log(err);
    }
};
