const {menuAggregate, categoryAggregate} = require("./aggregations")
const {parseClone, buildTree} = require("./helpers")


/*** Changed into async - await ***/
const Get_Menus = async (locale, models) => {
    try {
        const res = await models.menus.aggregate(menuAggregate(locale));
        let recursionChildren = buildTree(res, null);
        return parseClone(recursionChildren);
    } catch (err) {
        console.log(err, "_______ ERROR when getting menus _______")
        throw err;
    }
}


// function buildCategoryTree(data, locale, parentId = null) {
//     const tree = [];
//     data.filter(obj => obj.parent_id === parentId && obj.status == 1).forEach(obj => {
//         const translations = obj.translations.find(translation => translation.locale === locale);
//         const node = {
//             id: obj.id,
//             name: translations?.name,
//             slug: translations?.slug,
//             description: translations?.description,
//             meta_title: translations?.meta_title,
//             meta_description: translations?.meta_description,
//             meta_keywords: translations?.meta_keywords,
//             display_mode: translations?.display_mode,
//             children: [],
//             status: obj.status,
//             image: obj.image
//         };
//         const children = buildCategoryTree(data, locale, obj.id);
//         if (children.length > 0) {
//             node.children = children;
//         }
//         tree.push(node);
//     });
//
//     return tree;
// }


const Get_Categories = async (locale, models) => {
    try {
        const res = await models.categories.aggregate(categoryAggregate(locale));

        let recursionChildren = buildTree(res, null);
        return parseClone(recursionChildren);
    } catch (err) {
        console.log(err, "_______ ERROR when getting Get_Categories _______")
        throw err;
    }
}

// const Get_Categories = async (locale, models) => {
//     try {
//         const res = await models.categories.find();
//         const tree = buildCategoryTree(res, locale);
//         return parseClone(tree);
//     } catch (err) {
//         console.log(err, "_______ ERROR when getting categories _______")
//         throw err;
//     }
// }
const Get_Category_By_Search_Filter = async (catId, models) => {
    try {
        const res = await models.categories.find({parent_id: catId})
        return parseClone(res);
    } catch (err) {
        console.log(err, "_______ ERROR when getting search filter _______")
        throw err;
    }
}

module.exports = {
    Get_Menus,
    Get_Categories,
    Get_Category_By_Search_Filter,
}